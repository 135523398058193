import { memo, useMemo } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { separateRichTextNode } from '../../../helpers/common';
import { useInfoTabContext } from '../../../context/infotab-context';

const Warranty: React.FC = () => {
    const { product } = useInfoTabContext();

    const warranty: any = useMemo(() => {
        const seperatedKeyFeatures =  separateRichTextNode(product?.[0]?.fields?.warranty?.content || []);
        return seperatedKeyFeatures.map(element => ({
            nodeType: BLOCKS.DOCUMENT,
            content: element,
            data: {}
        }));
      }, [product]);

      const option = useMemo(() => {
        return {
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
                    return <img className='object-contain md:w-2/3 w-1/3 mx-auto mb-10' src={`${node?.data?.target?.fields?.file?.url}`} alt={`${node?.data?.target?.fields?.title}`} />
                  },
                [BLOCKS.HEADING_3]: (node: any, children: any) => <div className='pl-2 pb-4 font-inter text-20'>{children}</div>,
                
            }
        }
    }, []);
    return (
        <div className='relative'>
           <div className='w-full md:w-4/5 m-auto flex flex-col md:flex-row mt-14 gap-3'>
                { 
                  warranty?.[0] && documentToReactComponents({...warranty?.[0] || [], content: [warranty?.[0]?.content?.[0]]}, option)
                }
            <div>
                { 
                  warranty?.[0] && documentToReactComponents({...warranty?.[0] || [], content: [...warranty?.[0]?.content?.slice(1)]}, option)
                }
            </div>
        </div> 
            
        </div>
    );
};

export default memo(Warranty);