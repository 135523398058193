import { isValidElement, memo, useMemo } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { separateRichTextNode } from '../../../helpers/common';
import { useInfoTabContext } from '../../../context/infotab-context';

const FunctionTab: React.FC = () => {
    const { product } = useInfoTabContext();

    const functionTab: any = useMemo(() => {
        const seperatedFunctionTab =  separateRichTextNode(product?.[0]?.fields?.function?.content || []);
        return seperatedFunctionTab.map(element => ({
            nodeType: BLOCKS.DOCUMENT,
            content: element,
            data: {}
        }));
      }, [product]);

      const option = useMemo(() => {
        return {
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
                  return <img src={`${node?.data?.target?.fields?.file?.url}`} alt={`${node?.data?.target?.fields?.title}`} />
                },
                [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
                    const videoSrc = children?.[0];
        
                    if (isValidElement(videoSrc)) {
                      return <div>{children}</div>;
                    }
  
                    return children?.[0]?.includes('youtu') ? 
                      <div className='youtube-embeded'><iframe className='w-2/3 mx-auto h-full min-h-[400px] rounded-2xl' src={videoSrc} title='YouTube video player' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerPolicy='strict-origin-when-cross-origin' allowFullScreen></iframe></div>
                      : <div>{children}</div>;
                  },
                [BLOCKS.HEADING_3]: (node: any, children: any) => <div className='font-inter text-[24px] md:text-[36px] mb-5 md:px-20 capitalize'>{children}</div>,
                // [BLOCKS.PARAGRAPH]: (node: any, children: any) => <p className='pb-3'>{children}</p>
            }
        }
    }, []);

    return (
        <div className='m-auto flex flex-col gap-5 mb-10 md:mb-0 md:mt-14 w-full md:w-4/5 mx-auto'>
            {/* Content for Function tab */}
            {functionTab?.[0] && documentToReactComponents(functionTab?.[0], option)}
        </div>
    );
};

export default memo(FunctionTab);